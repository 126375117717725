import axios from "axios";
import { FaSearch } from "react-icons/fa";
import React, { useState, useEffect, useCallback, useRef } from "react";

import Select from "react-select";
import DriverMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";
import { FaRegEdit } from "react-icons/fa";
import "../SafariDashboard/SafariDashboard.css";
import { GrPlan } from "react-icons/gr";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { MdOutlineRefresh } from "react-icons/md";
import _ from "lodash";

const ALLBOOKINGS = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [height, setHeight] = useState(500);

  const handleResize = useCallback(
    _.debounce((event, { size }) => {
      setHeight(size.height);
    }, 100), // Adjust the debounce delay as needed (100ms here)
    []
  ); // Adjust debounce delay as needed
  const [editedColumn, setEditedColumn] = useState(null);

  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");

  const [search, setSearch] = useState("");
  const [driverAllTrip, setDriverAllTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state?.changeTheState);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [tripEditValues, setTripEditValues] = useState([]);
  const [safariPlanData, setSafariPlanData] = useState([]);
  const [ClientLists, setClientLists] = useState([]);
  const [tripreferenecNumber, setTripReferenceNumber] = useState("");
  const [tripNumberpass, setTripnumberpass] = useState("");
  const [DRIVERData, setDRIVERData] = useState([]);
  const [REPRESENTATIVEData, setREPRESENTATIVEData] = useState([]);
  console.log(ClientLists);
  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  const openPopup2 = () => {
    setIsOpen2(true);
  };

  const closePopup2 = () => {
    setIsOpen2(false);
  };
  const [driver, setDriver] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [rep, setRep] = useState([]);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  const handleCLientChange = (selectedClient) => {
    setSelectedClient(selectedClient.value);
  };

  const optionsCLient = [
    { value: "", label: "Select Client" },
    ...ClientLists.map((name) => ({
      value: name,
      label: name,
    })),
  ];

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  //console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const statusOptions = [
    { value: "notStarted", label: "Not Started" },
    { value: "started", label: "Started" },
    { value: "completed", label: "Completed" },
  ];

  const getStatus = (item) => {
    if (
      item?.STARTEDTRIP === null &&
      item?.ENDEDTRIP === null &&
      item?.STARTEDTRIPREP === null
    ) {
      return "notStarted";
    } else if (
      (item?.STARTEDTRIP === true || item?.STARTEDTRIPREP === true) &&
      item?.ENDEDTRIP === null
    ) {
      return "started";
    } else if (
      (item?.STARTEDTRIP === true || item?.STARTEDTRIPREP === true) &&
      item?.ENDEDTRIP === true
    ) {
      return "completed";
    } else {
      return "N/A";
    }
  };

  const driverTrip = () => {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getDriverTripStatus`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        const trips = response?.data;
        const sortedBookingData = [...response.data].sort((a, b) => {
          // Combine date and time into a comparable format (ISO format is ideal)
          const dateA = new Date(
            `${a.PLANPICKUPDATE?.slice(0, 10)}T${a.PLANPICKUPTIME?.slice(
              11,
              16
            )}`
          );
          const dateB = new Date(
            `${b.PLANPICKUPDATE?.slice(0, 10)}T${b.PLANPICKUPTIME?.slice(
              11,
              16
            )}`
          );

          // Sort in ascending order (1 if a > b, -1 if b > a)
          return dateA - dateB; // Ascending order
        });
        setDriverAllTrips(sortedBookingData);

        // Filter and deduplicate client names
        const uniqueClientNames = Array.from(
          new Set(trips.map((item) => item["CLIENT NAME"]).filter(Boolean))
        );
        setClientLists(uniqueClientNames);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${BASEURL}/getEmployeeListDriver`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setDriver(response?.data);
        //console.log(response?.data, "setDriverAllTrips");

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${BASEURL}/getEmployeeListRep`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setRep(response?.data);
        //console.log(response?.data, "getEmployeeListRep");

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${BASEURL}/getVehicleList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicle(response?.data);
        //console.log(response?.data, "setDriverAllTrips");

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBookingData = async () => {
    //console.log(selectedTransaction);

    try {
      const setDRIVERDataResponse = await axios.get(
        `${BASEURL}/getEmployeeLists?DESIGNATION=DRIVER`,
        {
          headers: { "auth-token": authToken, "session-token": sessiontoken },
        }
      );
      setDRIVERData(
        setDRIVERDataResponse.data.map((item) => ({
          label: item["EMPLOYEE NAME"].trim(),
          value: item["EMPLOYEE NAME"], // Using EMPLOYEE CODE here
          vehicle: item["VEHICLE ALLOCATED"],
        }))
      );
      //console.log(setDRIVERDataResponse.data);
    } catch (error) {
      console.error("Error fetching DRIVER data:", error);
    }

    try {
      const REPRESENTATIVEDataResponse = await axios.get(
        `${BASEURL}/getEmployeeLists?DESIGNATION=REP`,
        {
          headers: { "auth-token": authToken, "session-token": sessiontoken },
        }
      );
      setREPRESENTATIVEData(
        REPRESENTATIVEDataResponse.data.map((item) => ({
          label: item["EMPLOYEE NAME"].trim(),
          value: item["EMPLOYEE NAME"], // Using EMPLOYEE CODE here
        }))
      );
      //console.log(REPRESENTATIVEDataResponse.data);
    } catch (error) {
      console.error("Error fetching REPRESENTATIVE data:", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchBookingData();
  }, [BASEURL, authToken]);
  useEffect(() => {
    driverTrip();
  }, []);
  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };
  const filteredData = driverAllTrip?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return [
          "BOOKINGID",
          "CLIENT NAME",
          "TRIPNUMBER",
          "REFNO",
          "DRIVERNAME",
          "VEHICLEPLANNED",
          "PICKUPFROM",
          "DROPOFFPLACE",
          "STARTDATETIME",
          "ENDDATETIME",
          "STARTADDRESS",
          "ENDADDRESS",
          "REPNAME",
          "NARRATION",
          "AGENT NAME",
        ].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    const statusMatch =
      !selectedStatus || getStatus(item) === selectedStatus.value;

    const selectedClientMatch =
      !selectedClient || item["CLIENT NAME"] === selectedClient;

    const isDateRangeMatch = (startDateKey, endDateKey) => {
      return (
        (!fromDateFilter ||
          new Date(item[startDateKey]) >= new Date(fromDateFilter)) &&
        (!toDateFilter || new Date(item[endDateKey]) <= new Date(toDateFilter))
      );
    };

    if (
      selectedStatus?.value === "notStarted" ||
      selectedStatus?.value === undefined
    ) {
      return (
        textMatch &&
        statusMatch &&
        selectedClientMatch &&
        isDateRangeMatch("PLANPICKUPDATE", "PLANPICKUPDATE")
      );
    }
    if (selectedStatus?.value === "started") {
      return (
        textMatch &&
        statusMatch &&
        selectedClientMatch &&
        isDateRangeMatch("STARTDATETIME", "STARTDATETIME")
      );
    }
    if (selectedStatus?.value === "completed") {
      return (
        textMatch &&
        statusMatch &&
        selectedClientMatch &&
        isDateRangeMatch("ENDDATETIME", "ENDDATETIME")
      );
    }

    return textMatch && statusMatch && selectedClientMatch;
  });

  const getHighlightedText = (text) => {
    const searchTerms = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (text) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: highlightText(String(text), searchTerms),
          }}
        />
      );
    }
    return text;
  };
  const highlightText = (text, searchTerms) => {
    if (!searchTerms || searchTerms.length === 0) return text;

    const regex = new RegExp(`(${searchTerms.join("|")})`, "gi");
    return text.replace(
      regex,
      (match) => `<span class="highlight">${match}</span>`
    );
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const FetchTableValuesToEdit = (item, status) => {
    //console.log(status, "RATINGRATINGRATING");
    if (status !== "YET TO START") {
      alert("Trip has already been started.");
    } else {
      setTripEditValues(item);
      console.log(item);
      if (tripEditValues) {
        //console.log(tripEditValues);
        openPopup();
      }
    }
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function EditDriverDetails() {
    //console.log(tripEditValues);
    //console.log(tripEditValues?.BOOKINGID);
    console.log({
      BOOKINGID: tripEditValues?.BOOKINGID,
      TRIPID: tripEditValues?.TRIPNUMBER,
      PICKDATE: tripEditValues?.PLANPICKUPDATE,
      PICKTIME: tripEditValues?.PLANPICKUPTIME,
      DROPDATE: tripEditValues?.PLANDROPDATE,
      DROPTIME: tripEditValues?.PLANDROPTIME,
      PICKUPPLACE: tripEditValues?.PICKUPFROM,
      DROPOFFPLACE: tripEditValues?.DROPOFFPLACE,
      GUESTNAME: tripEditValues["CLIENT NAME"],
      NARRATION: tripEditValues?.NARRATION,
      VEHICLEPLANNED: tripEditValues?.VEHICLEPLANNED,
      DRIVERPLANNED: tripEditValues?.DRIVERNAME,
      REPPLANNED: tripEditValues?.REPNAME,
    });
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/editTripDetails`,
        {
          BOOKINGID: tripEditValues?.BOOKINGID,
          TRIPID: tripEditValues?.TRIPNUMBER,
          PICKDATE: tripEditValues?.PLANPICKUPDATE,
          PICKTIME: tripEditValues?.PLANPICKUPTIME,
          DROPDATE: tripEditValues?.PLANDROPDATE,
          DROPTIME: tripEditValues?.PLANDROPTIME,
          PICKUPPLACE: tripEditValues?.PICKUPFROM,
          DROPOFFPLACE: tripEditValues?.DROPOFFPLACE,
          GUESTNAME: tripEditValues["CLIENT NAME"],
          NARRATION: tripEditValues?.NARRATION,
          VEHICLEPLANNED: tripEditValues?.VEHICLEPLANNED,
          DRIVERPLANNED: tripEditValues?.DRIVERNAME,
          REPPLANNED: tripEditValues?.REPNAME,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        // setDriverAllTrips(response.data);
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function fetchSafariPlanData(item, status) {
    //console.log(item?.REFNO);
    //console.log(item?.TRIPNUMBER);
    const referenceNumber = item?.REFNO;
    const tripNumber = item?.TRIPNUMBER;
    setTripReferenceNumber(item?.REFNO);
    setTripnumberpass(item?.TRIPNUMBER);
    if (referenceNumber === "") {
      alert("Journey REF NO not available");
      return;
    }
    if (tripNumber === "") {
      alert("Journey TRIP NUMBER not available");
      return;
    }
    if (referenceNumber && tripNumber) {
      axios
        .post(
          `${BASEURL}/getSafariPlan`,
          {
            BOOKINGREF: referenceNumber,
            TRIPNUMBER: tripNumber,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          openPopup2();
          //console.log(response?.data);
          setSafariPlanData(response?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const updateBookingTrip = useCallback(
    (tripEditValues) => {
      const bookingDetails = {
        BOOKINGID: tripEditValues?.BOOKINGID,
        TRIPID: tripEditValues?.TRIPNUMBER,
        PICKDATE: tripEditValues?.PLANPICKUPDATE,
        PICKTIME: tripEditValues?.PLANPICKUPTIME,
        DROPDATE: tripEditValues?.PLANDROPDATE
          ? tripEditValues?.PLANDROPDATE
          : "",
        DROPTIME: tripEditValues?.PLANDROPTIME
          ? tripEditValues?.PLANDROPTIME
          : "",
        PICKUPPLACE: tripEditValues?.PICKUPFROM,
        DROPOFFPLACE: tripEditValues?.DROPOFFPLACE,
        GUESTNAME: tripEditValues?.["CLIENT NAME"],
        NARRATION: tripEditValues?.NARRATION,
        VEHICLEPLANNED: tripEditValues?.VEHICLEPLANNED
          ? tripEditValues?.VEHICLEPLANNED
          : "",
        DRIVERPLANNED: tripEditValues?.DRIVERNAME,
        REPPLANNED: tripEditValues?.REPNAME,
      };
      console.log(tripEditValues);
      console.log(bookingDetails);

      axios
        .post(`${BASEURL}/editTripDetails`, bookingDetails, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        })
        .then((response) => {
          // alert(response.data.message);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error updating booking trip:", error);
          setIsLoading(false);
        });
    },
    [BASEURL, authToken]
  );

  useEffect(() => {
    if (editedColumn) {
      updateBookingTrip(editedColumn);
    }
  }, [editedColumn, updateBookingTrip]);

  const handleDriverChange = (bookingId, selectedOption, item) => {
    console.log(item);
    console.log(selectedOption);
    const updatedBookingData = driverAllTrip.map((trip) => {
      if (trip.BOOKINGID === item.BOOKINGID) {
        return {
          ...trip,
          DRIVERNAME: selectedOption.label,
          DRIVERCODE: selectedOption.value,
        };
      }
      return trip;
    });

    setDriverAllTrips(updatedBookingData);
    const updatedTrip = updatedBookingData.find(
      (trip) => trip.BOOKINGID === item.BOOKINGID
    );
    updateBookingTrip(updatedTrip);
  };

  const handleRepresentativeChange = (bookingId, selectedOption, item) => {
    const updatedBookingData = driverAllTrip.map((trip) => {
      if (trip.BOOKINGID === item.BOOKINGID) {
        return {
          ...trip,
          REPNAME: selectedOption.label,
          REPCODE: selectedOption.value,
        };
      }
      return trip;
    });

    setDriverAllTrips(updatedBookingData);
    const updatedTrip = updatedBookingData.find(
      (trip) => trip.BOOKINGID === item.BOOKINGID
    );
    updateBookingTrip(updatedTrip);
  };

  console.log(selectedClient);
  const renderList = (
    <div>
      <div className="m-4">
        <div className="m-1 md:m-4 md:flex gap-4">
          <div className="lg:flex items-center">
            <p className="text-lg">Client &nbsp; </p>
            <div className="lg:w-[300px]  z-[500] ">
              <Select
                options={optionsCLient}
                value={{
                  value: selectedClient,
                  label: selectedClient || "Select ",
                }}
                onChange={handleCLientChange}
              />
            </div>
          </div>
          <div className="lg:flex items-center z-[500]">
            <p className="text-lg">Status &nbsp; </p>
            <div className="w-[200px] md:w-[200px] z-50 ">
              <Select
                options={statusOptions}
                value={selectedStatus}
                onChange={handleStatusChange}
              />
            </div>
          </div>
          <section className="lg:flex gap-3 items-center  lg:px-[20px] ">
            <div className="lg:ml-[10px] md:ml-0 flex items-center ">
              <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
              <input
                type="date"
                value={fromDateFilter}
                onChange={handleFromDateFilterChange}
              />
            </div>
            <div className="lf:ml-[10px] md:ml-0 flex items-center ">
              <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
              <input
                type="date"
                value={toDateFilter}
                onChange={handleToDateFilterChange}
              />
            </div>
          </section>
          <div className="flex items-center">
            <button
              className="md:m-2 px-3 py-1 bg-[#43505f] rounded text-white font-semibold"
              onClick={() => {
                window.location.reload();
              }}
            >
              Clear
            </button>
            <button
              className="md:m-2 px-3 py-2 bg-[#43505f] rounded text-white font-semibold"
              onClick={() => {
                driverTrip();
              }}
            >
              <MdOutlineRefresh />{" "}
            </button>
          </div>
        </div>
      </div>
      <aside className="table__reference__app">
        <div className="searchhhhhh mx-4">
          <p>Search</p>
          <input
            className="input_1 text-sm"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
        </div>

        <ResizableBox
          width="100%"
          height={height}
          minConstraints={[100, 100]}
          maxConstraints={[Infinity, Infinity]}
          resizeHandles={["s"]}
          onResize={handleResize}
        >
          <aside className="overflow-y-auto" style={{ height: "100%" }}>
            <table className="custom-table text-sm overflow__y__ relative">
              <thead
                className="header-cell sticky top-[0px] left-[10px]"
                style={{ zIndex: 100 }}
              >
                <tr>
                  <td>SNo</td>
                  <td>BOOKINGID</td>
                  <td>PICK DATE</td>
                  <td>PICK TIME</td>
                  <td>Client</td>
                  <td>REF NO</td>
                  <td>PAX</td>
                  <td>PICK UP POINT</td>
                  <td>DROP OFF POINT</td>
                  <td>DRIVER</td>
                  <td>REP</td>
                  <td>REMARKS</td>
                  <td>Agent</td>
                  <td>EDIT TRIPS</td>
                  <td>JOURNEY PLAN</td>
                </tr>
              </thead>
              <tbody>
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((item, index) => {
                    let status;
                    if (
                      item?.STARTEDTRIP === null &&
                      item?.ENDEDTRIP === null &&
                      item?.STARTEDTRIPREP === null
                    ) {
                      status = "YET TO START";
                    } else if (
                      (item?.STARTEDTRIP === true ||
                        item?.STARTEDTRIPREP === true) &&
                      item?.ENDEDTRIP === null
                    ) {
                      status = "STARTED";
                    } else if (
                      (item?.STARTEDTRIP === true ||
                        item?.STARTEDTRIPREP === true) &&
                      item?.ENDEDTRIP === true
                    ) {
                      status = "COMPLETED";
                    } else {
                      status = "N/A";
                    }

                    return (
                      <tr key={index} className="table-row">
                        <td className="text-right">{index + 1}</td>
                        <td>{item.BOOKINGID ? ` ${item.BOOKINGID}` : "N/A"}</td>
                        <td>
                          {item.PLANPICKUPTIME
                            ? `${formattedDate(item.PLANPICKUPDATE).slice(
                                0,
                                10
                              )}`
                            : "N/A"}
                        </td>

                        <td>
                          {item.PLANPICKUPTIME
                            ? ` ${item.PLANPICKUPTIME.slice(11, 19)}`
                            : "N/A"}
                        </td>
                        <td>
                          {getHighlightedText(
                            item["CLIENT NAME"] ? item["CLIENT NAME"] : "N/A"
                          )}
                        </td>
                        <td>
                          {getHighlightedText(item.REFNO ? item.REFNO : "N/A")}
                        </td>
                        <td>
                          {(item["ADULT PAX"] ?? 0) +
                            (item["INFANT PAX"] ?? 0) +
                            (item["CHILD PAX"] ?? 0)}
                        </td>
                        <td>
                          {getHighlightedText(
                            item.PICKUPFROM ? item.PICKUPFROM : "N/A"
                          )}
                        </td>
                        <td>
                          {getHighlightedText(
                            item.DROPOFFPLACE ? item.DROPOFFPLACE : "N/A"
                          )}
                        </td>
                        <td>
                          <div
                            style={{
                              width: "200px",
                              margin: "5px",
                              fontSize: "12px",
                            }}
                          >
                            <Select
                              options={DRIVERData}
                              defaultValue={{
                                label: item.DRIVERNAME || "SELECT",
                                value: item.DRIVERCODE || "SELECT",
                              }}
                              onChange={(option) =>
                                handleDriverChange(
                                  index.BOOKINGID,
                                  option,
                                  item
                                )
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              width: "200px",
                              margin: "5px",
                              fontSize: "12px",
                            }}
                          >
                            <Select
                              options={REPRESENTATIVEData}
                              defaultValue={{
                                label: item.REPNAME || "SELECT",
                                value: item.REPCODE || "SELECT",
                              }}
                              onChange={(option) =>
                                handleRepresentativeChange(
                                  index.BOOKINGID,
                                  option,
                                  item
                                )
                              }
                            />
                          </div>
                        </td>
                        <td>
                          {getHighlightedText(
                            item.NARRATION ? item.NARRATION : "N/A"
                          )}
                        </td>
                        <td>
                          {getHighlightedText(
                            item["AGENT NAME"] ? item["AGENT NAME"] : "N/A"
                          )}
                        </td>
                        <td
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          <p
                            className="text-center"
                            onClick={() => {
                              FetchTableValuesToEdit(item, status);
                              scrollToSection();
                            }}
                          >
                            <FaRegEdit />
                          </p>
                        </td>
                        <td
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          <p
                            onClick={() => {
                              fetchSafariPlanData(item, status);
                              scrollToSection();
                            }}
                          >
                            <GrPlan />
                          </p>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="14">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="mt-[30vh]"></div>
          </aside>
        </ResizableBox>
      </aside>

      <div>
        {isOpen && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                top: "52%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                background: "white",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1001,
              }}
              className="w-[95%] h-auto lg:w-[80%] "
            >
              <div className="flex justify-between">
                {" "}
                <header className="text-2xl font-bold text-[#002d62] font-serif">
                  <h1 className=" ">EDIT TRIP</h1>
                </header>
                <button
                  onClick={closePopup}
                  className=" font-bold text-2xl  text-blue-800"
                >
                  <IoMdClose />
                </button>
              </div>

              <section className="lg:flex my-4 gap-3 flex-wrap">
                <div className="flex gap-1 headers_details">
                  {/* <div className="flex gap-1  pop__up__trip__Edit"> */}
                  <p className="flex gap-5 ">
                    <h1>TRIP NUMBER : </h1>
                    <p className="text-red-600">{tripEditValues?.TRIPNUMBER}</p>
                    {/* </div> */}
                    <h1>REFERENCE NUMBER : </h1>
                    <p className="text-red-600">{tripEditValues?.REFNO}</p>
                    <h1>GUEST NAME : </h1>
                    <p className="text-red-600">
                      {tripEditValues["CLIENT NAME"]
                        ? tripEditValues["CLIENT NAME"]
                        : "N/A"}
                    </p>
                  </p>
                </div>
                <div className="flex gap-1  pop__up__trip__Edit">
                  <h1 className="">DRIVER :</h1>
                  <p className="">
                    <Select
                      value={{
                        value: tripEditValues?.DRIVERNAME,
                        label: tripEditValues?.DRIVERNAME,
                      }}
                      options={[
                        { value: "", label: "Select an option" },
                        ...driver.map((item) => ({
                          value: item["EMPLOYEE NAME"],
                          label: item["EMPLOYEE NAME"],
                        })),
                      ]}
                      onChange={(selectedOption) => {
                        // Update tripEditValues with the selected option
                        setTripEditValues((prevValues) => ({
                          ...prevValues,
                          DRIVERNAME: selectedOption.value,
                        }));
                      }}
                    />
                  </p>
                </div>

                <div className="flex gap-1 w-[45%] popup__2nd__div">
                  <h2 className="">REP NAME :</h2>
                  <p className="">
                    <Select
                      value={{
                        value: tripEditValues?.REPNAME,
                        label: tripEditValues?.REPNAME,
                      }}
                      options={[
                        { value: "", label: "Select an option" },
                        ...rep.map((item) => ({
                          value: item["EMPLOYEE NAME"],
                          label: item["EMPLOYEE NAME"],
                        })),
                      ]}
                      onChange={(selectedOption) => {
                        // Update tripEditValues with the selected option
                        setTripEditValues((prevValues) => ({
                          ...prevValues,
                          REPNAME: selectedOption.value,
                        }));
                      }}
                    />
                  </p>
                </div>

                <div className="flex gap-1 w-[45%] pop__up__trip__Edit">
                  <h1 className="">VEHICLE :</h1>
                  <p className="">
                    <Select
                      value={{
                        value: tripEditValues?.VEHICLEPLANNED,
                        label: tripEditValues?.VEHICLEPLANNED,
                      }}
                      options={[
                        { value: "", label: "Select an option" },
                        ...vehicle.map((item) => ({
                          value: item["VEHICLE REG NO"],
                          label: item["VEHICLE REG NO"],
                        })),
                      ]}
                      onChange={(selectedOption) => {
                        // Update tripEditValues with the selected option
                        setTripEditValues((prevValues) => ({
                          ...prevValues,
                          VEHICLEPLANNED: selectedOption.value,
                        }));
                      }}
                    />
                  </p>
                </div>
                {/* <div className="flex gap-1 w-[45%] popup__2nd__div">
                  <h2 className="">TOTAL SALES :</h2>
                  <h6 className="w-[300px]">
                    <input
                      type="text lg:w-[300px] "
                      style={{ width: "300px" }}
                    />
                  </h6>
                </div> */}
                <div className="flex gap-1 w-[45%] pop__up__trip__Edit"></div>
                <div className=" gap-1 w-[45%]  pop__up__trip__Edit">
                  <h1 className="">PICK UP PLACE :</h1>
                  <p className="">
                    <textarea
                      className="textmax__width border-solid border-black border-[1px] min-w-[100%] max-w-[100%] lg:min-w-[370px] lg:max-w-[370px] min-h-[80px] max-h-[80px]"
                      value={tripEditValues?.PICKUPFROM}
                      onChange={(e) =>
                        setTripEditValues((prevValues) => ({
                          ...prevValues,
                          PICKUPFROM: e.target.value,
                        }))
                      }
                    />
                  </p>
                </div>
                <div className="flex gap-1 w-[45%] pop__up__trip__Edit"></div>
                <div className=" gap-1 w-[45%] pop__up__trip__Edit ">
                  <h1 className="">DROP OFF PLACE :</h1>
                  <p className="">
                    <textarea
                      className="textmax__width border-solid border-black border-[1px] min-w-[100%] max-w-[100%] lg:min-w-[370px] lg:max-w-[370px] min-h-[80px] max-h-[80px]"
                      value={tripEditValues?.DROPOFFPLACE}
                      onChange={(e) =>
                        setTripEditValues((prevValues) => ({
                          ...prevValues,
                          DROPOFFPLACE: e.target.value,
                        }))
                      }
                    />
                  </p>
                </div>
              </section>
              <section>
                <div className=" gap-1 w-[45%] pop__up__trip__Edit">
                  <h1 className="">NARRATION :</h1>
                  <p className="">
                    <textarea
                      value={tripEditValues?.NARRATION}
                      className="textmax__width border-solid border-black border-[1px] min-w-[100%] max-w-[100%] lg:min-w-[370px] lg:max-w-[370px] min-h-[80px] max-h-[80px]"
                      onChange={(e) =>
                        setTripEditValues((prevValues) => ({
                          ...prevValues,
                          NARRATION: e.target.value,
                        }))
                      }
                      required
                    />
                  </p>
                </div>
              </section>

              <button
                onClick={() => EditDriverDetails(tripEditValues)}
                className="mt-[30px] px-3 py-1 rounded text-white bg-[#002d62]"
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
      <div>
        {isOpen2 && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                background: "white",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1001,
              }}
              className="w-[98%] h-auto lg:w-[50%] p-[10px] lg:p-[20px]"
            >
              <div className="flex justify-between">
                {" "}
                <header className="text-2xl font-bold text-[#002d62] font-serif">
                  <h1 className=" ">JOURNEY PLAN</h1>
                </header>
                <button
                  onClick={closePopup2}
                  className=" font-bold text-2xl  text-blue-800"
                >
                  <IoMdClose />
                </button>
              </div>
              <section className="px-2 lg:flex gap-4">
                <div className="flex gap-2">
                  <p>BOOKING REFERENCE : </p>
                  <p className="font-semibold text-red-600">
                    {tripreferenecNumber}
                  </p>
                </div>
                <div className="flex gap-2">
                  <p>TRIP NUMBER : </p>
                  <p className="font-semibold text-red-600">{tripNumberpass}</p>
                </div>
              </section>
              <aside className="table__reference__app ">
                {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
                <table className="custom-table ">
                  <thead className="header-cell">
                    <td>JOURNEY FROM </td>
                    <td>JOURNEY TO </td>
                    <td>START DATE </td>
                    <td>START TIME </td>
                  </thead>
                  <tbody>
                    {safariPlanData && safariPlanData.length > 0 ? (
                      safariPlanData.map((item, index) => {
                        return (
                          <tr key={index} className="table-row">
                            <td>
                              <p> {item.STARTFROM ? item.STARTFROM : "N/A"}</p>
                            </td>
                            <td>
                              <p> {item.ENDTO ? item.ENDTO : "N/A"}</p>
                            </td>
                            <td>
                              {item.STARTDATE
                                ? `${formattedDate(item.STARTDATE).slice(
                                    0,
                                    10
                                  )} `
                                : "N/A"}
                            </td>
                            <td>
                              {item.STARTTIME
                                ? ` ${item.STARTTIME.slice(11, 19)}`
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="11">Journey plan not available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </aside>
            </div>
          </>
        )}
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default ALLBOOKINGS;
