import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
const EntireTripDetails = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [search, setSearch] = useState("");
  const [driverAllTrip, setDriverAllTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state.changeTheState);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  //console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth.toISOString().split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate.toISOString().split("T")[0]
  );
  const driverTrip = () => {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getDriverTripStatus`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setDriverAllTrips(response?.data);
        //console.log(response?.data, "setDriverAllTrips");

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
    axios
      .get(`${BASEURL}/getEmployeeList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data[0]["EMPLOYEE NAME"], "getEmployeeList");
        //console.log(response, "getEmployeeList");
        setEmployeeList(
          response?.data?.map((item) => ({
            label: item["EMPLOYEE NAME"],
            value: item["EMPLOYEE NAME"],
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  useEffect(() => {
    driverTrip();
  }, []);
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };
  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };
  const statusOptions = [
    { value: "notStarted", label: "Not Started" },
    { value: "started", label: "Started" },
    { value: "completed", label: "Completed" },
  ];

  const getStatus = (item) => {
    if (
      item.STARTEDTRIP === null &&
      item.ENDEDTRIP === null &&
      item.STARTEDTRIPREP === null
    ) {
      return "notStarted";
    } else if (
      (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
      item.ENDEDTRIP === null
    ) {
      return "started";
    } else if (
      (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
      item.ENDEDTRIP === true
    ) {
      return "completed";
    } else {
      return "N/A";
    }
  };
  const filteredData = driverAllTrip?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) => {
        return [
          "BOOKINGID",
          "TRIPNUMBER",
          "REFNO",
          "DRIVERNAME",
          "VEHICLEPLANNED",
          "PICKUPFROM",
          "DROPOFFPLACE",
          "STARTDATETIME",
          "ENDDATETIME",
          "STARTADDRESS",
          "ENDADDRESS",
        ].some((key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        });
      });

    const statusMatch =
      !selectedStatus || getStatus(item) === selectedStatus.value;
    const employeeMatch =
      !selectedEmployee || selectedEmployee.value === item.DRIVERNAME;
    // const isDateRangeMatch =
    //   ((!fromDateFilter ||
    //     new Date(item.PLANPICKUPDATE) >= new Date(fromDateFilter)) &&
    //     (!toDateFilter ||
    //       new Date(item.PLANPICKUPDATE) <= new Date(toDateFilter))) ||
    //   ((!fromDateFilter ||
    //     new Date(item.STARTDATETIME) >= new Date(fromDateFilter)) &&
    //     (!toDateFilter ||
    //       new Date(item.STARTDATETIME) <= new Date(toDateFilter))) ||
    //   ((!fromDateFilter ||
    //     new Date(item.ENDDATETIME) >= new Date(fromDateFilter)) &&
    //     (!toDateFilter ||
    //       new Date(item.ENDDATETIME) <= new Date(toDateFilter)));

    // const isDateRangeMatch =
    //   (!fromDateFilter ||
    //     new Date(item?.STARTDATETIME) >= new Date(fromDateFilter)) &&
    //   (!toDateFilter || new Date(item?.ENDDATETIME) <= new Date(toDateFilter));

    if (
      selectedStatus?.value === "notStarted" ||
      selectedStatus?.value === undefined
    ) {
      const isDateRangeMatch =
        (!fromDateFilter ||
          new Date(item?.PLANPICKUPDATE) >= new Date(fromDateFilter)) &&
        (!toDateFilter ||
          new Date(item?.PLANPICKUPDATE) <= new Date(toDateFilter));
      return textMatch && statusMatch && isDateRangeMatch && employeeMatch;
    }
    if (selectedStatus?.value === "started") {
      const isDateRangeMatch =
        (!fromDateFilter ||
          new Date(item?.STARTDATETIME) >= new Date(fromDateFilter)) &&
        (!toDateFilter ||
          new Date(item?.STARTDATETIME) <= new Date(toDateFilter));
      return textMatch && statusMatch && isDateRangeMatch && employeeMatch;
    }
    if (selectedStatus?.value === "completed") {
      const isDateRangeMatch =
        (!fromDateFilter ||
          new Date(item?.ENDDATETIME) >= new Date(fromDateFilter)) &&
        (!toDateFilter ||
          new Date(item?.ENDDATETIME) <= new Date(toDateFilter));
      return textMatch && statusMatch && isDateRangeMatch && employeeMatch;
    }

    return textMatch && statusMatch && employeeMatch;
  });
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const renderList = (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page"> All Trip Details</h1>
      </header>
      <div className="m-4">
        <div className="m-1 md:m-4 md:flex gap-4">
          <div className="searchhhhhh mx-4">
            <p className="w-[120] lg:w-auto">Search&nbsp;</p>
            <input
              className="input_1"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>
          </div>
          <div className="flex items-center ">
            <p className="text-lg w-[72px] lg:w-auto">Status&nbsp; </p>
            <div className="w-[200px] md:w-[180px] z-[100] ">
              <Select
                options={statusOptions}
                value={selectedStatus}
                onChange={handleStatusChange}
              />
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-lg w-[120] lg:w-auto">DRIVER&nbsp; </p>
            <div className="w-[200px] md:w-[300px] my-1 z-50">
              <Select
                options={employeeList}
                value={selectedEmployee}
                onChange={handleEmployeeChange}
              />
            </div>
          </div>

          <div className="flex items-center">
            <button
              className="md:m-2 px-3 py-1 bg-[#43505f] rounded text-white font-semibold"
              onClick={() => {
                window.location.reload();
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <section className="lg:flex gap-3 items-center px-2 lg:px-[30px] ">
        <div className="ml-[10px] md:ml-0 flex items-center ">
          <p className="w-[50px] lg:w-auto">From</p>
          <input
            type="date"
            value={fromDateFilter}
            onChange={handleFromDateFilterChange}
          />
        </div>
        <div className="ml-[10px] md:ml-0 flex items-center ">
          <p className="w-[50px] lg:w-auto py-1">To</p>
          <input
            type="date"
            value={toDateFilter}
            onChange={handleToDateFilterChange}
          />
        </div>
      </section>
      <aside className="table__reference__app ">
        {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
        <table className="custom-table text-[12px]">
          <thead className="header-cell">
            <td>SNo</td>
            <td> PICK DATE</td>
            <td> PICK TIME</td>
            <td>Client </td>
            <td>REF NO</td>
            <td>PAX</td>
            <td>PICK UP POINT</td>
            <td>DROP OFF POINT</td>
            <td>DRIVER </td>
            <td>REP </td>
            <td>REMARKS</td>
            <td>Agent </td>
            {/* <td>BOOKING ID</td>

            <td>TRIP NUMBER</td>
            <td>REF NUMBER</td>
            <td>DRIVER </td>
            <td>REP Name</td>
            <td>Guest Name</td>
            <td>Total No of PAX</td>
            <td>AGENT NAME</td>
            <td>PLANNED PICKUP DATE & TIME</td> */}

            {/* <td>VEHICLE </td> */}
            {/* <td>PICK-UP FROM</td>
            <td>DROP-OFF PLACE</td>
            <td>NARRATION</td> */}
            {/* <td>PLANNED PICKUP TIME</td> */}
            {/* <td>START DATE TIME</td>
            <td>END DATE TIME</td> */}
            {/* <td>START ADDRESS</td>
            <td>END ADDRESS</td> */}
            <td>STATUS</td>
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData?.map((item, index) => {
                let status;
                if (
                  item.STARTEDTRIP === null &&
                  item.ENDEDTRIP === null &&
                  item.STARTEDTRIPREP === null
                ) {
                  status = "YET TO START";
                } else if (
                  (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
                  item.ENDEDTRIP === null
                ) {
                  status = "STARTED";
                } else if (
                  (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
                  item.ENDEDTRIP === true
                ) {
                  status = "COMPLETED";
                } else {
                  status = "N/A";
                }

                return (
                  <tr key={index} className="table-row">
                    <td className="text-right">{index}</td>{" "}
                    <td>
                      {item.PLANPICKUPTIME
                        ? `${formattedDate(item.PLANPICKUPDATE).slice(0, 10)}`
                        : "N/A"}
                    </td>{" "}
                    <td>
                      {item.PLANPICKUPTIME
                        ? ` ${item.PLANPICKUPTIME.slice(11, 19)}`
                        : "N/A"}
                    </td>{" "}
                    <td>
                      {" "}
                      {item["CLIENT NAME"]
                        ? item["CLIENT NAME"]
                        : "Not Available"}
                    </td>
                    <td>{item.REFNO ? item.REFNO : "N/A"}</td>
                    <td>
                      {(item["ADULT PAX"] ?? 0) +
                        (item["INFANT PAX"] ?? 0) +
                        (item["CHILD PAX"] ?? 0)}
                    </td>
                    <td>{item.PICKUPFROM ? item.PICKUPFROM : "N/A"}</td>
                    <td>{item.DROPOFFPLACE ? item.DROPOFFPLACE : "N/A"}</td>
                    <td>{item.DRIVERNAME ? item.DRIVERNAME : "N/A"}</td>
                    <td>{item.REPNAME ? item.REPNAME : "N/A"}</td>
                    <td>{item.NARRATION ? item.NARRATION : "N/A"}</td>
                    <td>
                      {" "}
                      {item["AGENT NAME"]
                        ? item["AGENT NAME"]
                        : "Not Available"}
                    </td>{" "}
                    {/*  */}
                    {/* <td className="text-right ">
                      <p className="pr-4">
                        {" "}
                        {item.BOOKINGID ? item.BOOKINGID : "N/A"}
                      </p>
                    </td>
                    <td className="text-right">
                      <p className="pr-4">
                        {" "}
                        {item.TRIPNUMBER ? item.TRIPNUMBER : "N/A"}{" "}
                      </p>
                    </td>
                    <td>{item.REFNO ? item.REFNO : "N/A"}</td>
                    <td>
                      {" "}
                      {item["CLIENT NAME"]
                        ? item["CLIENT NAME"]
                        : "Not Available"}
                    </td>
                    <td>{item.DRIVERNAME ? item.DRIVERNAME : "N/A"}</td>
                    <td>{item.REPNAME ? item.REPNAME : "N/A"}</td>
                    <td>
                      {(item["ADULT PAX"] ?? 0) +
                        (item["INFANT PAX"] ?? 0) +
                        (item["CHILD PAX"] ?? 0)}
                    </td>
                    <td>
                      {" "}
                      {item["AGENT NAME"]
                        ? item["AGENT NAME"]
                        : "Not Available"}
                    </td>{" "}
                    <td>
                      {item.PLANPICKUPTIME
                        ? `${formattedDate(item.PLANPICKUPDATE).slice(
                            0,
                            10
                          )} ${item.PLANPICKUPTIME.slice(11, 19)}`
                        : "N/A"}
                    </td> */}
                    {/* <td>{item.VEHICLEPLANNED ? item.VEHICLEPLANNED : "N/A"}</td> */}
                    {/* <td>{item.PICKUPFROM ? item.PICKUPFROM : "N/A"}</td>
                    <td>{item.DROPOFFPLACE ? item.DROPOFFPLACE : "N/A"}</td>
                    <td>{item.NARRATION ? item.NARRATION : "N/A"}</td> */}
                    {/* <td>
                      {item.PLANPICKUPTIME
                        ? ` ${item.PLANPICKUPTIME.slice(11, 19)}`
                        : "N/A"}
                    </td> */}
                    {/* <td>
                      {item.STARTDATETIME
                        ? `${formattedDate(item.STARTDATETIME).slice(
                            0,
                            10
                          )} ${item.STARTTIME.slice(11, 19)}`
                        : "N/A"}
                    </td>
                    <td>
                      {item.ENDDATETIME
                        ? `${formattedDate(item.ENDDATETIME).slice(
                            0,
                            10
                          )} ${item.ENDTIME.slice(11, 19)}`
                        : "N/A"}
                    </td> */}
                    {/* <td>{item.STARTADDRESS ? item.STARTADDRESS : "N/A"}</td>
                    <td>{item.ENDADDRESS ? item.ENDADDRESS : "N/A"}</td> */}
                    <td>{status}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="11">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </aside>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default EntireTripDetails;
